import React from "react";
import { Route, Routes } from "react-router-dom";

import Participate from "./participate";
import Exhibitdetails from "./exhibit-details";
import Imperfectlovers from "./imperfect-lovers";
import Lover0 from "./lover0";
import Lover1 from "./lover1";
import Moreinfo from "./more-info";
import Admin from "./admin";
import Home from "./home";

class Menu extends React.Component {
  render() {
    return (
      <div>
        <Routes>
          <Route
            path="/"
            element={<Home onDataFromMenu={this.props.onMenuVis} />}
          />
          <Route
            path="/home"
            element={<Home onDataFromMenu={this.props.onMenuVis} />}
          />
          <Route
            path="/participate"
            element={<Participate onDataFromMenu={this.props.onMenuVis} />}
          />
          <Route
            path="/exhibit-details"
            element={<Exhibitdetails onDataFromMenu={this.props.onMenuVis} />}
          />
          <Route
            path="/imperfect-lovers-live"
            element={<Imperfectlovers onDataFromMenu={this.props.onMenuVis} />}
          />
          <Route
            path="/lover-0"
            element={<Lover0 onDataFromMenu={this.props.onMenuVis} />}
          />
          <Route
            path="/lover-1"
            element={<Lover1 onDataFromMenu={this.props.onMenuVis} />}
          />
          <Route
            path="/more-info"
            element={<Moreinfo onDataFromMenu={this.props.onMenuVis} />}
          />
          <Route
            path="/admin"
            element={<Admin onDataFromMenu={this.props.onMenuVis} />}
          />
        </Routes>

        <div className="w3-top"
              style={{ zIndex:"5000" }}>
          <div
            className="w3-bar w3-white w3-padding w3-card"
            style={{ letterSpacing: "2px" }}
          >
            {/* Logo */}
            <a href="/" className="w3-bar-item w3-button w3-hide-small">
              Imperfect Lovers
            </a>

            {/* Right-sided navbar links */}
            <div className="w3-right">
              {/* Participate */}
              <a href="/participate" className="w3-bar-item w3-button">
                participate
              </a>

              {/* Dropdown */}
              <div className="w3-dropdown-hover">
                <a href="/exhibit-details" className="w3-bar w3-button">
                  view
                </a>
                <div className="w3-dropdown-content w3-bar-block w3-card-4">
                  <a href="/exhibit-details" className="w3-bar-item w3-button">
                    exhibit details
                  </a>
                  <a
                    href="/imperfect-lovers-live" target="_blank"
                    className="w3-bar-item w3-button"
                  >
                    Imperfect Lovers
                  </a>
                  <a href="/lover-0" target="_blank" className="w3-bar-item w3-button">
                    Lover 0
                  </a>
                  <a href="/lover-1" target="_blank" className="w3-bar-item w3-button">
                    Lover 1
                  </a>
                  <a href="https://opensea.io/collection/imperfect-lovers/overview" target="_blank" className="w3-bar-item w3-button">
                    on OpenSea
                  </a>
                </div>
              </div>

              {/* More Info */}
              <a href="/more-info" className="w3-bar-item w3-button">
                more info
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Menu;
