import React from "react";
import Footer from "./footer";

import Webconnection from "../web3";
import { detectAndSetWeb3 } from "../web3";
import { getWebcall } from "../web3";

import { imlovABI } from "../imlov";
import { imlovaddress } from "../imlov";
import { coopABI } from "../cooperator";
import { coopaddress } from "../cooperator";

let web3;

class Moreinfo extends React.Component {
  //state vars for web3
  state = { web3: null, accounts: null, imlov: null, cooperator: null };

  // var to show or not show menu
  sendDataToMenu = () => {
    const data = true;
    // Call the callback function passed from Menu
    this.props.onDataFromMenu(data);
  };

  //connect contracts via how ever web is connected
  async contracts() {
    const imlov = new this.state.web3.eth.Contract(imlovABI, imlovaddress);
    const cooperator = new this.state.web3.eth.Contract(coopABI, coopaddress);
    this.setState({ imlov });
    this.setState({ cooperator });
  }

  //connect metamask
  onConnectButton = async (event) => {
    const connect = await window.ethereum.request({ method: "eth_accounts" });

    if (connect.length === 0) {
      await getWebcall();
      web3 = await detectAndSetWeb3();
      this.setState({ web3 }, () => {
        // Callback function to insure state/contract before transact
        this.contracts();
      });
      const accounts = await web3.eth.getAccounts();
      this.setState({ accounts });
    }
  };

  // on load
  async componentDidMount() {
    //menu or not?
    this.sendDataToMenu();

    //title of page
    document.title = "Imperfect Lovers - Exhibit Details";

    //connect to web3 - either infura or metamasl
    if (!this.state.web3) {
      web3 = await detectAndSetWeb3();

      const accounts = await web3.eth.getAccounts();
      this.setState({ accounts });

      this.setState({ web3 }, () => {
        this.contracts();
      });
    }
  }

  render() {
    return (
      <div>
        <Webconnection />

        <header
          className="w3-display-container w3-content"
          style={{ maxWidth: "100%", minWidth: "500px", marginTop: "50px" }}
          id="home"
        >
          <a href="/">
            <img
              className="w3-image"
              src="./images/imperfect-lovers.png"
              alt="cropped Imperfect Lovers"
            />
          </a>
        </header>

        <div
          className="w3-right"
          style={{ width: "230px", marginRight: "5px", marginTop: "-5px" }}
        >
          {this.state.accounts != 0 && (
            <button
              type="button"
              disabled
              className="connect
      w3-button w3-light-grey w3-section"
            >
              connected: {this.state.accounts}
            </button>
          )}

          {this.state.accounts == 0 && (
            <button
              className="connect
      w3-button w3-light-grey w3-section"
              onClick={this.onConnectButton}
            >
              connect wallet
            </button>
          )}
        </div>

        <div className="w3-content">
          <div className="w3-padding-32" id="about">
            <div className="w3-padding-large">
              <h1>Exhibit Details <br /></h1>
              <br />

              
   

              <p>
                <i>Imperfect Lovers</i> is conceived as both a virtual and
                actual work, where the on-chain Smart Contracts are core - and
                allow for new and different interfaces in the future - while
                viewership is meant to be a physical and visceral experience.
              </p>

            
              <img src="./images/single-lover-person.jpg" width="100%" />
          

              <p>
                Open either Lover (or both together, if you want them in one
                interface) by clicking below, or using the menu above. Please
                view in fullscreen, without the toolbar or bookmark bar showing
                (settings are under "view" in your browser). The Lovers will always
                automatically update with each new on-chain transaction.
              </p>

              <ul>
                <li>
                  <a href="./lover-0" target="_blank" rel="noopener noreferrer">
                    Lover 0
                  </a>
                </li>
                <li>
                  <a href="./lover-1" target="_blank" rel="noopener noreferrer">
                    Lover 1
                  </a>
                </li>
                <li>
                  <a
                    href="./imperfect-lovers-live"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    both Imperfect Lovers
                  </a>
                </li>
              </ul>

              <p>
                The ideal exhibit format is two, facing, large square screens
                (or projections), each displaying one of the two Lovers, and allowing us to walk between them. These
                screens can be rectangular if necessary - the background color
                will extend.
              </p>

              <img src="./images/single-lover.jpg" width="100%" />

              <p>
                Curators can also decide how else they might create experiences
                for loving and being loved. The Lovers can be shown side by side
                in different browsers on one screen, on facing phones or
                buildings, in a single interface... While these are not how I
                originally intended viewership, each is understood as acceptable
                and <i>felt.</i>
              </p>

              <p>
                Please contact the artist if you'd like to consult on an
                installation:{" "}
                <a
                  href="https://nathanielstern.art/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  nathanielstern.art
                </a>
              </p>

              <img src="./images/two-windows.png" width="100%" />
              <br /> <br />
              <img src="./images/imperfect-lovers-screens.jpg" width="100%" />

            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
export default Moreinfo;
