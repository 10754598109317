import React from "react";
import Footer from "./footer";
//npx serve -s build
import Webconnection from "../web3";
import { detectAndSetWeb3 } from "../web3";
import { getWebcall } from "../web3";

import { imlovABI } from "../imlov";
import { imlovaddress } from "../imlov";
import { useState } from "react";

import { useEffect, useLayoutEffect } from "react";

import { gsap } from "gsap";
import CSSPlugin from "gsap/CSSPlugin";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger, useGSAP, CSSPlugin);

function Home(props) {
  const C = CSSPlugin;
  const [decodedSVG, setDecodedSVG] = useState("");
  const [newDecodedSVG, setNewDecodedSVG] = useState("");
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [imlov, setImlov] = useState(null);
  var [intervalId, setIntervalId] = useState(null);
  var [intervalId2, setIntervalId2] = useState(null);

  const sendDataToMenu = () => {
    const data = true;
    // Call the callback function passed from Menu
    props.onDataFromMenu(data);
  };

  const onConnectButton = async (event) => {
    const connect = await window.ethereum.request({ method: "eth_accounts" });

    if (connect.length === 0) {
      await getWebcall();
      const web3Instance = await detectAndSetWeb3();
      setWeb3(web3Instance);
      const accountsList = await web3Instance.eth.getAccounts();
      setAccounts(accountsList);
      onMount();
    }
  };

  const onMount = async () => {
    const web3Instance = await detectAndSetWeb3();
    const accountsList = await web3Instance.eth.getAccounts();
    setAccounts(accountsList);
    setWeb3(web3Instance);

    const imlovInstance = new web3Instance.eth.Contract(imlovABI, imlovaddress);
    setImlov(imlovInstance);

    const tokenURI = await imlovInstance.methods.tokenURI(0).call();
    const tokenURIStr = tokenURI.replace("data:application/json;utf8,", "");
    const encodedSVG = JSON.parse(tokenURIStr).image.replace(
      "data:image/svg+xml;base64,",
      ""
    );
    setDecodedSVG(String(atob(encodedSVG)));
  };

  useEffect(() => {
    sendDataToMenu();
    document.title = "Imperfect Lovers by Nathaniel Stern";
    onMount();

    const fetchOnChainValue = async () => {
      try {
        const tokenURI2 = await imlov.methods.tokenURI(0).call();
        const tokenURIStr2 = tokenURI2.replace(
          "data:application/json;utf8,",
          ""
        );
        const encodedSVG2 = JSON.parse(tokenURIStr2).image.replace(
          "data:image/svg+xml;base64,",
          ""
        );
        const newDecodedSVGtemp = String(atob(encodedSVG2));

        if (newDecodedSVGtemp !== decodedSVG) {
          setNewDecodedSVG(newDecodedSVGtemp);
          setDecodedSVG(newDecodedSVGtemp);
        }
      } catch (error) {
        console.error("Error fetching on-chain value:", error);
      }
    };

    const interval = setInterval(fetchOnChainValue, 300000); // Poll every 10 minutes
    setIntervalId(interval);

    const interval2 = setInterval(() => {
      if (!decodedSVG) {
        onMount();
        clearInterval(interval2);
      }
    }, 3000);
    setIntervalId2(interval2);

    return () => {
      clearInterval(interval);
      clearInterval(interval2);
    };
  }, [decodedSVG]);

  useEffect(() => {
    const preloadImages = () => {
      for (let i = 0; i <= 50; i++) {
        const img = new Image();
        img.src = `./images/imperfect-daylies/${i}.png`;
      }
    };

    preloadImages();
  }, []); // Empty dependency array ensures this runs only once

  useGSAP(() => {
    // gsap code here...

    const tl = gsap.timeline();
    tl.fromTo(
      ".slide-2",
      { opacity: 0, yPercent: 100 },
      { opacity: 1, yPercent: 0 }
    )
      .fromTo(".slide-3", { opacity: 0 }, { opacity: 1 })
      .fromTo(".slide-4", { opacity: 0 }, { opacity: 1 })
      .fromTo(".slide-5", { opacity: 0 }, { opacity: 1 })
      .fromTo(".slide-6", { opacity: 0 }, { opacity: 1 }, "slide-blank")
      .fromTo(".slide-blank", { opacity: 0 }, { opacity: 1 }, "slide-blank")

      .fromTo(
        ".slide-6",
        { opacity: 1, yPercent: 0 },
        { opacity: 0, yPercent: -150 },
        "sametime2"
      )
      .fromTo(
        ".slide-7",
        { opacity: 0, yPercent: 100 },
        { opacity: 1, yPercent: 0 },
        "sametime2"
      )

      .fromTo(".slide-7aa", { opacity: 0 }, { opacity: 1 })
      .fromTo(".slide-7ab", { opacity: 0 }, { opacity: 1 })

      .fromTo(".slide-7ba", { opacity: 0 }, { opacity: 1 })
      .fromTo(".slide-7bb", { opacity: 0 }, { opacity: 1 })

      .fromTo(
        ".slide-7ab img ",
        { height: "70vh" },
        { height: "55vh" },
        "resizeSVG"
      )
      .fromTo(
        ".slide-7bb img ",
        { height: "70vh" },
        { height: "55vh" },
        "resizeSVG"
      )

      .fromTo(
        ".slide-8",
        { opacity: 0, yPercent: 100 },
        { opacity: 1, yPercent: 0 },
        "resizeSVG"
      )

      .fromTo(".slide-9", { opacity: 0 }, { opacity: 1 })
      .fromTo(".slide-10", { opacity: 0 }, { opacity: 1 }, "longHand")

      .fromTo(".slide-11-left", { opacity: 0 }, { opacity: 1 }, "longHand")
      .fromTo(".slide-11-left-txt", { opacity: 0 }, { opacity: 1 }, "longHand")
      .fromTo(
        ".slide-11-left-img",
        { rotation: 0 },
        { rotation: -117 },
        "longHand"
      )

      .fromTo(".slide-11-right", { opacity: 0 }, { opacity: 1 }, "longHand")

      .fromTo(
        ".slide-11-right-img",
        { rotation: 0 },
        { rotation: -117 },
        "longHand"
      )

      .fromTo(".slide-10", { opacity: 1 }, { opacity: 0 }, "eth-out")
      .fromTo(".slide-9", { opacity: 1 }, { opacity: 0 }, "eth-out")

      .fromTo(".slide-8", { opacity: 1 }, { opacity: 0 }, "eth-out")

      .fromTo(".slide-12", { opacity: 0 }, { opacity: 1 })
      .fromTo(".slide-13", { opacity: 0 }, { opacity: 1 }, "text-out")

      .fromTo(".slide-11-left-txt", { opacity: 1 }, { opacity: 0 }, "text-out")

      .fromTo(".slide-14", { opacity: 0 }, { opacity: 1 }, "gift")

      .fromTo(".slide-15-left", { opacity: 0 }, { opacity: 1 }, "gift")
      .fromTo(".slide-15-left-txt", { opacity: 0 }, { opacity: 1 }, "gift")

      .fromTo(".slide-15-right", { opacity: 0 }, { opacity: 1 }, "gift")

      .fromTo(".slide-15-left-txt", { opacity: 1 }, { opacity: 0 }, "%-out")
      .fromTo(".slide-12", { opacity: 1 }, { opacity: 0 }, "%-out")
      .fromTo(".slide-13", { opacity: 1 }, { opacity: 0 }, "%-out")
      .fromTo(".slide-14", { opacity: 1 }, { opacity: 0 }, "%-out")

      .fromTo(".slide-7ab img", { height: "55vh" }, { height: "70vh" }, "care")
      .fromTo(".slide-7bb img", { height: "55vh" }, { height: "70vh" }, "care")

      .fromTo(
        ".slide-15-left-img",
        { height: "55vh" },
        { height: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-15-right-img",
        { height: "55vh" },
        { height: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-15-left img",
        { height: "55vh" },
        { height: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-15-right img",
        { height: "55vh" },
        { height: "70vh" },
        "care"
      )

      .fromTo(
        ".slide-15-left-img",
        { width: "55vh" },
        { width: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-15-right-img",
        { width: "55vh" },
        { width: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-15-left img",
        { width: "55vh" },
        { width: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-15-right img",
        { width: "55vh" },
        { width: "70vh" },
        "care"
      )

      .fromTo(
        ".slide-11-left-img",
        { height: "55vh" },
        { height: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-11-right-img",
        { height: "55vh" },
        { height: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-11-left img",
        { height: "55vh" },
        { height: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-11-right img",
        { height: "55vh" },
        { height: "70vh" },
        "care"
      )

      .fromTo(
        ".slide-11-left-img",
        { width: "55vh" },
        { width: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-11-right-img",
        { width: "55vh" },
        { width: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-11-left img",
        { width: "55vh" },
        { width: "70vh" },
        "care"
      )
      .fromTo(
        ".slide-11-right img",
        { width: "55vh" },
        { width: "70vh" },
        "care"
      )

      .fromTo(".slide-15a", { opacity: 0 }, { opacity: 1 }, "mirror")
      .fromTo(".slide-15b", { opacity: 0 }, { opacity: 1 }, "mirror")
      .fromTo(".slide-15c", { opacity: 0 }, { opacity: 1 })
      .fromTo(".slide-15d", { opacity: 0 }, { opacity: 1 })

      .fromTo(".slide-16", { opacity: 0 }, { opacity: 1 })

      .fromTo(".slide-15b", { opacity: 1 }, { opacity: 0 }, "care-out")
      .fromTo(".slide-15c", { opacity: 1 }, { opacity: 0 }, "care-out")
      .fromTo(".slide-15d", { opacity: 1 }, { opacity: 0 }, "care-out")
      .fromTo(".slide-blank2", { opacity: 0 }, { opacity: 1 })

      .fromTo(".slide-16", { opacity: 1 }, { opacity: 1 })

      .fromTo(".slide-blank3", { opacity: 0 }, { opacity: 1 });

    ScrollTrigger.create({
      animation: tl,
      trigger: ".container",
      start: "top 15%",
      end: "+=20000vh",
      scrub: 0.3,
      pin: true,
      anticipatePin: 1,
    });

    // gsap code here...
    const tl2 = gsap.timeline();

    for (let i = 0; i < 51; i++) {
      tl2.fromTo(".c2img" + i, { opacity: 0 }, { opacity: 1 });
    }

    tl2.fromTo(".c2-overlay", { opacity: 1 }, { opacity: 0 }, "portraits-out");

    tl2.fromTo(
      ".slide-blank4",
      { opacity: 0 },
      { opacity: 1 },
      "portraits-out"
    );

    const tl3 = gsap.timeline();
    tl3
      .fromTo(".c2-overlay", { opacity: 0 }, { opacity: 1, duration: 0.15 })
      .fromTo(".c2-overlay1", { opacity: 0 }, { opacity: 1, duration: 0.15 })
      .fromTo(".c2-overlay2", { opacity: 0 }, { opacity: 1, duration: 0.15 })
      .fromTo(".c2-overlay2", { opacity: 1 }, { opacity: 1, duration: 0.15 });

    const tl4 = gsap.timeline();
    tl4.fromTo(".c4-overlay", { opacity: 0 }, { opacity: 1, duration: 0.01 });

    tl4.fromTo(
      ".c4-over",
      { marginLeft: 0 },
      { marginLeft: "100%", duration: 0.8 },
      "timeline"
    );

    tl4.fromTo(
      ".c4-over-text",
      { opacity: 0 },
      { opacity: 1, duration: 0.3 },
      "timeline"
    );

    tl4.fromTo(
      ".c4-slideblank",
      { opacity: 0 },
      { opacity: 1, duration: 0.05 }
    );

    tl4.fromTo(
      ".c4-slide1",
      { opacity: 0 },
      { opacity: 1, duration: 0.25 },
      "rotatem"
    );

    tl4.fromTo(
      ".zerominutec",
      { rotate: -180 },
      { rotate: 0, duration: 0.4 },
      "rotatem"
    );

    tl4.fromTo(
      ".zerominutecc",
      { rotate: 180 },
      { rotate: 0, duration: 0.4 },
      "rotatem"
    );

    tl4.fromTo(".zerominutecc", { rotate: 0 }, { rotate: 0, duration: 0.02 });

    tl4.fromTo(".participate", { opacity: 0 }, { opacity: 1, duration: 0.25 });

    tl4.fromTo(
      ".participate-overlay",
      { opacity: 0 },
      { opacity: 1, duration: 0.25 }
    );

    tl4.fromTo(
      ".participate-overlay2",
      { opacity: 0 },
      { opacity: 1, duration: 0.25 }
    );

    tl4.fromTo(
      ".participate-overlay3",
      { opacity: 0 },
      { opacity: 1, duration: 0.25 }
    );

    tl4.fromTo(".last-slide", { opacity: 0 }, { opacity: 1, duration: 0.25 });

    tl4.fromTo(".last-slide2", { opacity: 0 }, { opacity: 1, duration: 0.25 });

    tl4.fromTo(".last-slide3", { opacity: 0 }, { opacity: 1, duration: 0.25 });

    tl4.fromTo(".final", { opacity: 0 }, { opacity: 1, duration: 0.25 });

    tl4.fromTo(".up", { opacity: 0 }, { opacity: 0.5, duration: 0.7 });

    tl4.fromTo(".imperfect", { opacity: 0 }, { opacity: 1, duration: 1 });

    ScrollTrigger.create({
      animation: tl2,
      trigger: ".container2",
      start: "top 15%",
      end: "+=4000vh",
      scrub: 0.3,
      pin: true,
      anticipatePin: 1,
    });

    ScrollTrigger.create({
      animation: tl3,
      trigger: ".container3",
      start: "top 15%",
      end: "+=4000vh",
      scrub: 0.3,
      pin: true,
      anticipatePin: 1,
    });

    ScrollTrigger.create({
      animation: tl4,
      trigger: ".container4",
      start: "top 15%",
      end: "+=15000vh",
      scrub: 0.3,
      pin: true,
      anticipatePin: 1,
    });
  });

  //do the push thing here and then use the tostring?

  return (
    <div>
      <Webconnection />

      <link rel="stylesheet" href="animations.css" />

      <div
        className="w3-right"
        style={{ width: "230px", marginRight: "5%", marginTop: "-5px"  }}
      >
        {accounts != 0 && (
          <button
            type="button"
            disabled
            className="connect
      w3-button w3-light-grey w3-section"
          >
            connected: {accounts}
          </button>
        )}

        {accounts == 0 && (
          <button
            className="connect
      w3-button w3-light-grey w3-section"

   
            onClick={onConnectButton}
          >
            connect wallet
          </button>
        )}
      </div>

      <div style={{ marginLeft: "10%", marginTop: "80px", position: "sticky" }}>
        <h1>Imperfect Lovers</h1>
        <h5>
          a continuous networked performance <br />
          inaugurated February 23, 2024
          <br />
          by Nathaniel Stern{" "}
          <a href="https://nathanielstern.art/">
            {" "}
            &lt;https://nathanielstern.art/&gt;
          </a>
        </h5>
        <br />
      </div>

      <div style={{ textAlign: "right", marginRight: "6%" }}>
        <a href="#jump">skip intro scroll</a>
      </div>

      <div className="container">
        <div className="slide-1"></div>

        <div className="slide-2">Imperfect Lovers</div>
        <div className="slide-3">
          Imperfect Lovers
          <div
            style={{
              fontSize: "calc(2vw + 2vh)",
              WebkitTextStroke: "0",
              color: "#444444",
            }}
          >
            A conceptual performance piece, <br /> <br />
            <br />
          </div>
        </div>

        <div className="slide-4">
          Imperfect Lovers
          <div
            style={{
              fontSize: "calc(2vw + 2vh)",
              WebkitTextStroke: "0",
              color: "#444444",
            }}
          >
            A conceptual performance piece,
            <br />
            conceived and coded by artist and professor Nathaniel Stern,
            <br />
          </div>
        </div>

        <div className="slide-5">
          Imperfect Lovers
          <div
            style={{
              fontSize: "calc(2vw + 2vh)",
              WebkitTextStroke: "0",
              color: "#444444",
            }}
          >
            A conceptual performance piece,
            <br />
            conceived and coded by artist and professor Nathaniel Stern, <br />
            enacted as two interlocking, and nearly identical, smart contracts
            — 
          </div>
        </div>
        <div className="slide-blank"> </div>
        <div className="slide-6">
          Imperfect Lovers
          <div
            style={{
              fontSize: "calc(2vw + 2vh)",
              WebkitTextStroke: "0",
              color: "#444444",
            }}
          >
            <br />
            <div style={{ fontSize: "calc(2.8vw + 2.8vh)" }}>
              two lovers, linked forever in mutual care.
            </div>
          </div>
        </div>

        <div className="slide-7">
          <div className="slide-7a">
            <div className="slide-7aa">
              <div style={{ paddingLeft: "1vh" }}>Lover #0 </div>
            </div>
            <div className="slide-7ab">
              <img src="./images/lover-empty.svg" />
            </div>
          </div>

          <div className="slide-7b">
            <div className="slide-7ba">
              <div style={{ paddingRight: "1vh" }}>Lover #1 </div>
            </div>
            <div className="slide-7bb">
              <img src="./images/lover-empty.svg" />
            </div>
          </div>

          <div className="slide-8">
            At the start of the performance, the Lovers - presented as clocks -
          </div>

          <div className="slide-9">
            At the start of the performance, the Lovers - presented as clocks -{" "}
            <br />
            were both seeded with a balance of 0.65 ETH.
          </div>

          <div className="slide-10">
            At the start of the performance, the Lovers - presented as clocks -{" "}
            <br />
            were both seeded with a balance of 0.65 ETH. <br />
            <i>The long hand shows a Lover's current balance.</i>
          </div>

          <div className="slide-11-left">
            <div className="slide-11-left-txt">
              <img src="./images/lover-text.svg" />
            </div>

            <div className="slide-11-left-img">
              <img src="./images/lover-minute.svg" />
            </div>
          </div>

          <div className="slide-11-right">
            <div className="slide-11-left-txt">
              <img src="./images/lover-text.svg" />
            </div>
            <div className="slide-11-right-img">
              <img src="./images/lover-minute.svg" />
            </div>
          </div>

          <div className="slide-12">
            Once per day, the Lovers attempt to gift
          </div>

          <div className="slide-13">
            Once per day, the Lovers attempt to gift <br />a percentage from
            their balances to the other.
          </div>

          <div className="slide-14">
            Once per day, the Lovers attempt to gift <br />
            a percentage from their balances to the other.
            <br />
            <i> The short hand tells how much a Lover gifted that day.</i>
          </div>

          <div className="slide-15-left">
            <div className="slide-15-left-txt">
              <img src="./images/lover-hour-text.svg" />
            </div>

            <div className="slide-15-left-img">
              <img src="./images/lover-hour.svg" />
            </div>
          </div>

          <div className="slide-15-right">
            <div className="slide-15-left-txt">
              <img src="./images/lover-hour-text.svg" />
            </div>
            <div className="slide-15-right-img">
              <img src="./images/lover-hour.svg" />
            </div>
          </div>

          <div className="slide-15a"></div>

          <div className="slide-15b">
            The left side of each clock shows the other Lover's balance and
            gift,
          </div>

          <div className="slide-15c">
            The left side of each clock shows the other Lover's balance and
            gift, <br />
            while the second (red) hand shows the real passage of time,
          </div>

          <div className="slide-15d">
            The left side of each clock shows the other Lover's balance and
            gift, <br />
            while the second (red) hand shows the real passage of time, <br />
            making the two clocks <i>mirror images</i>, each presenting <br />
          </div>
          <div className="slide-blank2"> </div>
          <div className="slide-16">a daily act of reciprocity & care.</div>

          <div className="slide-blank3"> </div>
        </div>
      </div>

      <div className="container2">
        <div className="container3">
          <div className="posAbsfull c2-overlay">
            <div className="posAbsfull c2-overlay1">
              As time passes, their love remains steadfast. <br />
            </div>

            <div className="posAbsfull c2-overlay2">
              <br /> Even while, with each transaction, gas costs chip <br />
              away at the Lovers' overall balance.
            </div>
          </div>
        </div>

        <div className="c2img0"></div>

        <div className="c2img1"></div>

        <div className="img2"></div>

        <div className="c2img3"></div>

        <div className="c2img4"></div>

        <div className="c2img5"></div>

        <div className="c2img6"></div>

        <div className="c2img7"></div>

        <div className="c2img8"></div>

        <div className="c2img9"></div>

        <div className="c2img9"></div>

        <div className="c2img10"></div>

        <div className="c2img11"></div>

        <div className="c2img12"></div>

        <div className="c2img13"></div>

        <div className="c2img14"></div>

        <div className="c2img15"></div>

        <div className="c2img16"></div>

        <div className="c2img17"></div>

        <div className="c2img18"></div>

        <div className="c2img19"></div>

        <div className="c2img20"></div>

        <div className="c2img21"></div>

        <div className="c2img22"></div>

        <div className="c2img23"></div>

        <div className="c2img24"></div>

        <div className="c2img25"></div>

        <div className="c2img26"></div>

        <div className="c2img27"></div>

        <div className="c2img28"></div>

        <div className="c2img29"></div>

        <div className="c2img30"></div>

        <div className="c2img31"></div>

        <div className="c2img32"></div>

        <div className="c2img33"></div>

        <div className="c2img34"></div>

        <div className="c2img35"></div>

        <div className="c2img36"></div>

        <div className="c2img37"></div>

        <div className="c2img38"></div>

        <div className="c2img39"></div>

        <div className="c2img40"></div>

        <div className="c2img41"></div>

        <div className="c2img42"></div>

        <div className="c2img43"></div>

        <div className="c2img44"></div>

        <div className="c2img45"></div>

        <div className="c2img46"></div>

        <div className="c2img47"></div>

        <div className="c2img48"></div>

        <div className="c2img49"></div>

        <div className="c2img50"></div>

        <div className="slide-blank4 posAbsfull"></div>
      </div>

      <div className="container4">
        <div className="posAbs2 c4-overlay">
          <div className="posAbs2 c4-img">
            <div className="posAbs2 c4-over"></div>

            <div className="posAbs2 c4-over-text">
              <div
                style={{
                  position: "relative",
                  width: "75%",
                  left: "20vw",
                  top: "3vh",
                  color: "rgb(180,0,0)",
                  fontSize: "calc(1.8vw + 1.8vh)",
                }}
              >
                Their balances slowly move toward zero.
              </div>
            </div>

            <div className="posAbs2  c4-slideblank"></div>

            <div className="posAbs2 c4-slide1">
              <div className="posAbs2">
                <img src="./images/lover-eth.svg" />
              </div>

              <div className="posAbs2 zerominutec">
                <img src="./images/lover-minute.svg" />
              </div>

              <div className="posAbs2 zerominutecc">
                <img src="./images/lover-minute.svg" />
              </div>

              <div
                style={{
                  position: "relative",
                  top: "2vh",
                  color: "rgb(180,0,0)",
                  zIndex: "2600",
                  fontSize: "calc(1.8vw + 1.8vh)",
                  height: "calc(18w + 10vh)",
                  backgroundColor: "rgba(255,255,255,.8)",
                }}
              >
                And yet, because of their (smart) contract, <br />
                they will continue trying to give
                <br />
                even when there's nothing left.
              </div>
            </div>
          </div>

          <div className="participate posAbs2">
            <div className="participate-overlay posAbs2">
              Online participants trigger the Lovers' <gr />
              daily giftings to one another.
              <br /> <br />
              <br />
              <div className="participate-overlay2 posAbs2">
                <br />
                <br /> <br />
                They pay only gas fees, which are mostly refunded - <br />{" "}
                bringing the Lovers' balances down.
                <br /> <br />
              </div>
              <div className="participate-overlay3 posAbs2">
                <br /> <br />
                <br />
                <br /> <br />
                <br />
                And they receive a daily snapshot, or portrait, of <br />
                the Lovers as part of that interaction.
              </div>
            </div>
          </div>

          <div className="last-slide">
            <div
              className="last-slide1"
              style={{
                marginTop: "10vh",
              }}
            >
              All daily and historical Lover data is fully <br />
              on-chain and permanently available,
            </div>

            <div className="last-slide2">
              so that other creatives might make their own <br />
              interfaces for the Lovers.
            </div>

            <div className="last-slide3">They ask:</div>
          </div>

          <div className="final posAbs2">
            <div
              className="imperfect posAbs2"
              style={{
                color: "white",
                zIndex: "4700",
                fontSize: "calc(5vw + 5vh)",
              }}
            >
              Imperfect Lovers
            </div>
            <div className="up">
              <div style={{ marginTop: "19vh" }}>
                What does it look like to <br />
                give beyond your capacity?
              </div>
            </div>

            <div style={{ marginTop: "19vh" }}>
              What does it look like to <br />
              give beyond your capacity?
            </div>
          </div>
        </div>
      </div>

      <div id="jump" style={{ position: "relative", top: "-140vh" }}></div>

      <div
        className="w3-content"
        style={{ position: "relative", top: "-140vh", zIndex: "5000" }}
      >
        <div className="w3-padding-32" id="about">
          <div className="w3-padding-large">
            <p
              style={{
                fontSize: "calc(1.6vw + 1.6vh)",
                textAlign: "center",
                color: "#444444",
                fontFamily: '"Times New Roman", Georgia, Serif',
              }}
            >
              Watch and listen to Nathaniel Stern <br />
              talk through <i>Imperfect Lovers</i> (4 min video)
              <br /> <br />
              <iframe
                style={{ aspectRatio: "16 / 9", width: "100%", height: "auto" }}
                src="https://www.youtube.com/embed/_tPZ4QhxBJo?si=OQHCXCSjWS5cRzIQ"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </p>
            <div style={{ textAlign: "right" }}>
              <a href="#top">back to top</a>
            </div>
            <p>
              <i>Imperfect Lovers</i> performs two coded, on-chain Lovers, each
              giving the other "everything they have," until there is literally
              "nothing left to give." Even then, they continue to attempt
              trans-actions to and for one another, failing unless and until
              they are somehow replenished. Money is always and only a metaphor,
              and the truest of gifts can only be given when there is no-thing
              left to give. Here, the Blockchain is leveraged to show intimacy
              and care, time and mortality, imperfection and romance, despite
              (and not because of) all that glitters and shines.
            </p>

            <div
              className="w3-display-container w3-light-grey"
              style={{ width: "100%" }}
            >
              <a href="/imperfect-lovers-live" target="blank">
                <div
                  dangerouslySetInnerHTML={{ __html: decodedSVG }}
                  alt="Imperfect Lovers SVG"
                />
              </a>

              <span className="w3-tag w3-light-grey w3-small w3-left-align w3-hide-small">
                The two, live, fully on-chain Lovers.{" "}
                <a
                  href="https://opensea.io/collection/imperfect-lovers/overview"
                  target="_blank"
                >
                  View on OpenSea
                </a>
              </span>
            </div>

            <p>
              Once per day, the <i>Imperfect Lovers</i> each allocate a portion
              of themselves to the other, always losing a bit of their balance
              in the process. External viewers experience the Lovers through
              browser-based lenses, in any number of ways. The preferred
              installation &ndash; in a museum or gallery &ndash; has us walking
              between two body-sized and facing LCD screens or projections in a
              low-light, intimate setting. We see them as simple five-armed
              clocks, where each “body” is a timepiece subtly changing in size,
              beating/ticking backward and forward, breathing in and up, out and
              down, all based on how much is “left in the tank” of each wallet
              (the minute hands), and what they most recently gave and received
              (the hour hands), while seconds tick by in real time. These
              dynamic yet subtle (and fully on-chain) animations can also be
              viewed or exhibited at home or elsewhere &ndash; in individual
              browsers that are side by side, together in one interface, across
              facing smartphones, as building-sized projections, or wherever we
              want their imperfect love <i>felt</i>.
            </p>

            <p>
              The <i>Imperfect Lovers</i> live as unique Smart Contracts on the
              Ethereum Blockchain, with an initial sum of money donated by the
              artist. Once inaugurated, each is promised to gift the other
              between 25% and 78% of their account &ndash; since relationships
              are never fully equal &ndash; up to once per day. And with every
              transfer, a little more is depleted from their overall balance
              because of the required “gas” fees. Eventually, neither will be
              able to afford any transfer; but their <i>promised giving</i> will
              carry on for as long as the Blockchain exists and functions, every
              participant-activated success or failure exhausting them further
              still. Even when “running on empty,” they continue to{" "}
              <i>promise</i> and <i>try</i>: “I would if I could; I will when I
              can.”
            </p>

            <p>
              Participants can engage with this ongoing performance in multiple
              ways. First and foremost, they must trigger the intra-action.
              Although the Lovers automatically allocate some of themselves (as
              Eth) to each other, because of Smart Contract constraints, the
              transfer <i>itself</i> has to be prompted by an external wallet.
              At or after 12pm CST (1pm during daylight savings) on any given
              day, one and only one person can pay gas fees to enact that
              transfer, and they will receive a unique NFT: a <i>Portrait</i> of
              the Lovers at that precise moment. If available, they will also be
              partially reimbursed for what they paid in gas &ndash; up to .01
              Eth, dependent on the Lovers&apos; balances and current block fees
              &ndash; consequently depleting the Lovers with every gifting. This
              decelerating reciprocity of care will carry on until each Lover is
              left with only 1 wei, or 10
              <sup className="w3-small">-18</sup> Eth, after which point they
              still <i>attempt</i> to give, but fail.
            </p>

            <img
              src="images/blue-portrait.png"
              alt="Imperfect Lovers blue portrait"
              width="100%"
            />
            <div
              className="w3-display-container w3-light-grey"
              style={{ width: "100%" }}
            >
              <span className="w3-tag w3-light-grey w3-small w3-left-align w3-hide-small">
                Sample Blockchain-generated daily Portrait of the Lovers.
                Limited to only one per day.{" "}
                <a
                  href="https://opensea.io/collection/imperfect-lovers/overview"
                  target="_blank"
                >
                  View on OpenSea
                </a>
              </span>
            </div>

            <p>
              Participants might also <a href="./participate">donate</a> to the
              main “co_operator” contract or either Lover (and thus to both),
              whose wallet addresses are public, thus filling their cups and/or
              making them active again. If they donate .125 Eth or more, they
              also have the option of leaving their name and/or a message on its
              public <a href="./participate">“Donor Wall,”</a> which lives
              permanently on-chain. Any donations will immediately be seen via
              the <i>Imperfect Lovers</i> data feed API, and become part of the
              Lovers&apos; image-based balancing with their next trans-action.
            </p>

            <p>
              Anyone can also view and/or exhibit the work, proposing
              traditional or experimental curation for the project IRL. And
              &ndash; since the <i>actual</i> work is the Smart Contract(s)
              &ndash; they can design their own interfaces/bodies for the
              Lovers, using the simple API / data feeds that I have made
              publicly available.
            </p>

            <p>
              The title <i>Imperfect Lovers</i>, and its use of clocks, are a
              reference to Felix Gonzalez Torres&apos; (FGT&apos;s) similarly
              named piece, <i>Untitled (Perfect Lovers)</i>. FGT produced his
              two battery operated clocks, slowly falling out of sync and dying,
              for his partner, Ross. It was both a romantic gesture and a direct
              response to the AIDS crisis, the lack of funding and care going to
              research HIV and support those with it, and the extreme violence
              against LGBTQ communities that continues to take on many forms to
              this day. <i>Imperfect Lovers</i> cites the romanticism in
              FGT&apos;s original, and also continues to question “care” during
              late stage capitalism, most specifically the post-pandemic mental
              health crisis. <i>Care takes time</i>, yet we are in a
              socioeconomic culture that values speed above all else. How might
              we find and give time and care in this space? Where can love and
              health, action and balance, thrive now?
            </p>

            <p>
              <i>Imperfect Lovers</i> is also a redundant proclamation: all
              lovers are imperfect. And how love itself might be expressed and
              experienced is also far from flawless. While beneath the surface
              and unseen (in our contracts and promises, hearts and intentions,
              and more) might be a constant attempt at giving and gifting, how
              we articulate and feel loving and being loved is often
              misunderstood, and changes over time and relationships. For this
              reason, curators, collectors, I, and others might not only view
              the <i>Imperfect Lovers</i> contracts via my clockwork, web-based
              software, but update, make, or commission new and different ways
              of exploring and experiencing them &ndash; new inter-faces &ndash;
              per above.
            </p>

            <img
              src="images/FGT-Perfect-Lovers.png"
              alt="Untitled (Perfect Lovers)"
              width="100%"
            />
            <div
              className="w3-display-container w3-light-grey"
              style={{ width: "100%" }}
            >
              <span className="w3-tag w3-small w3-light-grey w3-left-align w3-hide-small">
                FGT&apos;s original <i>Untitled (Perfect Lovers)</i>
              </span>
            </div>

            <p>
              Alongside its potent explorations of time and gifting, one
              question <i>Imperfect Lovers</i> asks is: Who funds love? However
              we use it, the Blockchain was built on capitalist, libertarian
              principles. Perhaps curators or collectors might provide Eth for a
              specific exhibition; or romantics &ndash; like me &ndash; might
              donate as a symbolic gift, leaving poetry or messages on-chain
              along with that gifting. But at its core, the artwork&apos;s
              potency is always explicitly outside its funding. Having Nothing =
              Given/Giving Everything.
            </p>

            <p>
              The takeaway NFT is also an FGT reference &ndash; to{" "}
              <i>Untitled (Portrait of Ross in LA)</i>, a finite/forever gift of
              life and love re-presented as a pile of candy. And the palettes of
              the Portraits are inspired by the most common colors in On
              Kawara&apos;s <i>Today</i> series.
            </p>

            <p>
              With <i>Imperfect Lovers</i>, as in life, the <i>potential</i>{" "}
              gift means more than the <i>reality</i>. It&apos;s easy &ndash; or
              at least <i>easier</i> &ndash; to give when we have abundance. But
              to give beyond our own capacity is an impossible yet beautiful
              promise: that of forever. Forever can <i>only</i> exist in
              potential. Forever can only be real, if I promise to <i>give</i>,
              if I attempt to share, even when I <i>can&apos;t</i>. The
              impossible promise of forever is, in some ways, the only promise
              worth making. It&apos;s that combination of promise beyond failure
              that is precisely what makes love so precious. Once initiated,
              these <i>Imperfect Lovers</i> will always trans-act, together and
              apart, successfully or not.
            </p>

            <p>&ndash; &ndash; &ndash;</p>
            <img
              src="images/red-portrait.png"
              alt="Imperfect Lovers blue portrait"
              width="100%"
            />
            <div
              className="w3-display-container w3-light-grey"
              style={{ width: "100%" }}
            >
              <span className="w3-tag w3-light-grey w3-small w3-left-align w3-hide-small">
                Sample Blockchain-generated daily Portrait of the Lovers.
                Limited to only one per day.{" "}
                <a
                  href="https://opensea.io/collection/imperfect-lovers/overview"
                  target="_blank"
                >
                  View on OpenSea
                </a>
              </span>
            </div>

            <p>
              <i>Imperfect Lovers</i> is funded in part by the{" "}
              <a href="https://www.refractionfestival.com/">Refraction</a>{" "}
              Community Arts Fund (partial minting costs and seed money for the
              Lovers) and a Dean&apos;s award from Peck School of the Arts,
              University of Wisconsin-Milwaukee (which paid for some technical
              consulting/support from{" "}
              <a href="https://steviep.xyz/">Steve Pikelny</a>).{" "}
              <a href="https://kingduane.com/">Duane King</a> assisted with the
              SVG clock design, and{" "}
              <a href="https://linktr.ee/haiverart">Haiver</a> helped develop
              the "scrollytelling" storyboard.
            </p>

            <div style={{ textAlign: "right" }}>
              <a href="#top">back to top</a>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "-140vh" }}>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
