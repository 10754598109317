import React from "react";
import Footer from "./footer";

import Webconnection from "../web3";
import { detectAndSetWeb3 } from "../web3";
import { getWebcall } from "../web3";

import { imlovABI } from "../imlov";
import { imlovaddress } from "../imlov";
import { coopABI } from "../cooperator";
import { coopaddress } from "../cooperator";

let web3;

class Participate extends React.Component {
  state = {
    decodedSVG: "",
    donordata: [],
    value: "",
    donortext: "",
    formattedtime: "",
    nextday: "",
    donormessage: "",
    mintmessage: "",
    accounts: [],
    lover0: "",
    lover1: "",
    decodedSVG2: "",
    web3: null,
    imlov: null,
    cooperator: null,
    timediff: null,
    timeleft: null,
    gift1:"",
    gift2:"",
    balance1:"",
    balance2:"",
    nftURL:"",
    oldTotes:null
  };

  sendDataToMenu = () => {
    const data = true;
    // Call the callback function passed from Menu
    this.props.onDataFromMenu(data);
  };

  //connect contracts via how ever web is connected
  async contracts() {
    const imlov = new this.state.web3.eth.Contract(imlovABI, imlovaddress);
    const cooperator = new this.state.web3.eth.Contract(coopABI, coopaddress);
    this.setState({ imlov });
    this.setState({ cooperator });
  }

  //connect metamask
  onConnectButton = async (event) => {
    const connect = await window.ethereum.request({ method: "eth_accounts" });

    if (connect.length === 0) {
      await getWebcall();
      web3 = await detectAndSetWeb3();
      this.setState({ web3 }, () => {
        // Callback function to insure state/contract before transact
        this.contracts();
      });
      const accounts = await web3.eth.getAccounts();
      this.setState({ accounts });
    }
  };

  //connect MetaMask
  async connectMetaMask() {
    try {
      const connect = await window.ethereum.request({ method: "eth_accounts" });

      if (connect.length === 0) {
        web3 = await getWebcall();

        this.setState({ web3 });
        const accounts = await web3.eth.getAccounts();

        this.setState({ accounts });
      }
    } catch (error) {
      console.error("Error connecting MetaMask:", error);
      // Handle error
    }
  }

  //transact and mint

  onTransact = async (event) => {
    event.preventDefault(); // override form submission with this event
    this.setState({ mintmessage: "Gifting... trans-acting... minting..." });
    await this.connectMetaMask();
    await this.initializeMintContract();
  };

  async initializeMintContract() {
    try {
      const imlov = new this.state.web3.eth.Contract(imlovABI, imlovaddress);

      // Initialize contract if not already initialized

      this.setState({ imlov }, async () => {
        // Callback function to insure state/contract before transact
        await this.transact();
      });
    } catch (error) {
      console.error("Error initializing contract:", error);
      throw new Error("Error initializing contract.");
    }
  }

  async transact() {
    await this.state.imlov.methods
      .mint(this.state.accounts[0])
      .send({
        gasLimit: 360000,
        from: this.state.accounts[0],
      })
      .then((receipt) => {
        this.setState({
          mintmessage: "The Lovers have co-operated. Mint successful.",
        });
      })
      .catch((error) => {
        this.setState({
          mintmessage:
            "Trans-action unsuccessful. You most likely need to wait for the next possible mint time (above; refresh for latest). See etherscan for more/other details.",
        });
      });
  }

  //reload if minted
  async mintDone() {
    if(!this.state.mintmessage){
    if(this.state.oldTotes){
    const newTot = parseInt(String(await this.state.imlov.methods.totalSupply().call()));
if (this.state.oldTotes!=newTot){
  window.location.reload()
}
    }}
  }

  // donorwall needs

  async initializeCoopContract() {
    try {
      // Initialize contract if not already initialized

      const cooperator = new this.state.web3.eth.Contract(coopABI, coopaddress);

      this.setState({ cooperator }, () => {
        // Callback function to insure state/contract before transact
        this.donor();
      });
    } catch (error) {
      console.error("Error initializing contract:", error);
      throw new Error("Error initializing contract.");
    }
  }

  async donor() {
    await this.state.cooperator.methods
      .donorWall(this.state.donortext)
      .send({
        value: web3.utils.toWei(this.state.value, "ether"),
        gasLimit: 200000,
        from: this.state.accounts[0],
      })

      .then((receipt) => {
        // console.log("Transaction successful:", receipt);
        this.setState({
          donormessage:
            "Success! Balances will be updated with next mint. Allow a minute or two and refresh to see your message.",
        });
      })

      .catch((error) => {
        // console.error("Error executing transaction:", error);
        this.setState({
          donormessage:
            "Transaction reverted. Most likely it's a Metamask issue, you did not donate enough Eth, or there was an on-chain error. See etherscan for more/other details.",
        });
      });
  }

  //donorwall function/ add error messaging after testing
  onSubmit = async (event) => {
    // form submission custom event
    event.preventDefault(); // override form submission with this event
    //const accounts = await web3.eth.getAccounts(); // get accounts to send from

    this.setState({ donormessage: "Submitting donation and message..." });

    await this.connectMetaMask();
    await this.initializeCoopContract();
  };

  async componentDidMount() {
    this.sendDataToMenu();

    document.title = "Imperfect Lovers - Participate";
    if (!this.state.web3) {
      web3 = await detectAndSetWeb3();

      const accounts = await web3.eth.getAccounts();
      this.setState({ accounts }, async () => {

      this.setState({ web3 }, async () => {
        const imlov = new this.state.web3.eth.Contract(imlovABI, imlovaddress);
        this.setState({ imlov }, async () => {
          //imlov needs
          // most recent mint
          const tokenURI = await this.state.imlov.methods
            .tokenURI(
              parseInt(
                String(await this.state.imlov.methods.totalSupply().call())
              ) - 1
            )
            .call();
            
            const oldTotes = parseInt(String(await this.state.imlov.methods.totalSupply().call()));

            this.setState({oldTotes})
          const tokenURIStr = tokenURI.replace(
            "data:application/json;utf8,",
            ""
          );
          const encodedSVG = JSON.parse(tokenURIStr).image.replace(
            "data:image/svg+xml;base64,",
            ""
          );
          const decodedSVG = String(atob(encodedSVG));
          this.setState({ decodedSVG });

          const tokenURI2 = await this.state.imlov.methods.tokenURI(3).call();
          const tokenURIStr2 = tokenURI2.replace(
            "data:application/json;utf8,",
            ""
          );
          const encodedSVG2 = JSON.parse(tokenURIStr2).image.replace(
            "data:image/svg+xml;base64,",
            ""
          );
          const decodedSVG2 = String(atob(encodedSVG2));
          this.setState({ decodedSVG2 });

          // for transact form
          const lastmintdata = await this.state.imlov.methods
            .getPortraitData(
              parseInt(
                String(await this.state.imlov.methods.totalSupply().call())
              ) - 1
            )
            .call();
          const lastminttime = parseInt(String(lastmintdata[0]));
          //console.log(lastminttime)

          // last mint time
          let dateObj = new Date(lastminttime * 1000);
          //console.log('dateObj: '+dateObj)
          let currTime = new Date();
          currTime.setHours(currTime.getHours() - 6);
          //console.log('currTime: '+currTime)

          // in CST
          dateObj.setUTCHours(dateObj.getUTCHours() - 6); 

          let leapyear = 0;
          if(dateObj.getUTCMonth() == 1) {// if Feb
          if(dateObj.getUTCDate() > 28) {// if Feb 29
      
leapyear =new Date(lastminttime * 1000);
leapyear.setUTCHours(leapyear.getUTCHours() - 6);
leapyear.setUTCDate(leapyear.getUTCDate() + 1);
leapyear.setUTCHours(18);
leapyear.setUTCMinutes(0);
leapyear.setUTCSeconds(0);


          dateObj.setUTCMonth(2);   
            }}

           
          let month = dateObj.getUTCMonth();
       month=month+1
          //console.log(lastminttime)
          let day = dateObj.getUTCDate();
          // Get hours from the timestamp
          let hours = dateObj.getUTCHours();
          // Get minutes part from the timestamp
          let minutes = dateObj.getUTCMinutes();
          // Get seconds part from the timestamp
          //let seconds = dateObj.getUTCSeconds();
          let formattedtime =
            month.toString().padStart(2, "0") +
            "/" +
            day.toString().padStart(2, "0") +
            ", " +
            hours.toString().padStart(2, "0") +
            ":" +
            minutes.toString().padStart(2, "0");
          //       + ':' + seconds.toString().padStart(2, '0'); //do i really need seconds?
          //console.log(formattedtime);

          //for next mint time
          dateObj.setUTCDate(dateObj.getUTCDate() + 1);


          if(dateObj.getUTCMonth() == 1) {// if Feb
            if(dateObj.getUTCDate() > 29) {// if Feb 29
            dateObj.setUTCMonth(2);   
            dateObj.setUTCDate(1);   }}

          month = dateObj.getUTCMonth();
       month=month+1
          day = dateObj.getUTCDate();
          let nextday =
            month.toString().padStart(2, "0") +
            "/" +
            day.toString().padStart(2, "0");

          dateObj.setUTCHours(18);
          dateObj.setUTCMinutes(0);
          dateObj.setUTCSeconds(0);

          if(leapyear!=0){dateObj=leapyear}

          

          setInterval(() => {

            currTime = new Date();

            let timeleft = dateObj - currTime;            
            if (timeleft < 0) {
              timeleft = 0;
              this.mintDone();
            }
            this.setState({ timeleft });

            let seconds = Math.floor((timeleft / 1000) % 60);
            minutes = Math.floor((timeleft / (1000 * 60)) % 60);
            hours = Math.floor((timeleft / (1000 * 60 * 60)) );

            hours = hours < 10 ? "0" + hours : hours;
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            let timediff = hours + ":" + minutes + ":" + seconds;

            this.setState({ timediff });

            


            //console.log(timediff)
          }, 1000);

          this.setState({ formattedtime });
          this.setState({ nextday });

          //donorwall

          const lover0 = await this.state.imlov.methods.lover0().call();
          this.setState({ lover0 });

          const lover1 = await this.state.imlov.methods.lover0().call();
          this.setState({ lover1 });

          const cooperator = new this.state.web3.eth.Contract(
            coopABI,
            coopaddress
          );
          this.setState({ cooperator }, async () => {
            //coop needs

            let donors = await this.state.cooperator.methods
              .getDonorWall()
              .call();
            let donordata = [];
            for (let i = 0; i < donors.length; i++) {
              donordata.push([
                donors[i][0],
                donors[i][1],
                web3.utils.fromWei(parseInt(String(donors[i][2])), "ether") +
                  " Eth",
              ]);
            }

            //maybe replace this with .eth address if it exists?
            let renderedDonors = donordata.map((donor, index) => (
              <div key={index}>
                <div>
                  <h4
                    className="w3-tag w3-light-grey"
                    style={{ width: "100%", textAlign: "left" }}
                  >
                    {donor[0]}
                  </h4>{" "}
                  <div className="w3-text-grey">address: {donor[1]}</div>{" "}
                  <div className="w3-text-grey">donated {donor[2]}</div>
                </div>{" "}
                <br />
              </div>
            ));

            if (donordata.length < 1) {
              renderedDonors = (
                <div>
                  <h4
                    className="w3-tag w3-light-grey"
                    style={{ width: "100%", textAlign: "left" }}
                  >
                    <i>Imperfect Lovers</i> was supported with 2 Eth from the
                    Refraction Community Arts Fund, and $750 from Peck School of
                    the Arts, UW-Milwaukee
                  </h4>
                  <div className="w3-text-grey">no donors yet</div>
                </div>
              );
            }
            const loverdata = await cooperator.methods.getLoverData().call();
            const nftNum = (
              parseInt(
                String(await this.state.imlov.methods.totalSupply().call())
              ) - 1
            )
            const nftURL = "https://opensea.io/assets/ethereum/0x60f660e599186369049130f03bbcc7070dc52874/"+String(nftNum)
                  this.setState({nftURL})
            this.setState({ gift1: parseInt(String(loverdata.gift1)) });
            this.setState({ gift2: parseInt(String(loverdata.gift2)) });
            this.setState({ balance1: web3.utils.fromWei(parseInt(String(loverdata.balance1)), 'ether') });
            this.setState({ balance2: web3.utils.fromWei(parseInt(String(loverdata.balance2)), 'ether') });
            this.setState({ renderedDonors });

            this.setState({ renderedDonors });
          });
        });
      });
    });
    
    }
  }

  render() {
    return (
      <div>
        <Webconnection />

        <header
          className="w3-display-container w3-content"
          style={{ maxWidth: "100%", minWidth: "500px", marginTop: "50px" }}
          id="home"
        >
          <a href="/">
            <img
              className="w3-image"
              src="./images/imperfect-lovers.png"
              alt="cropped Imperfect Lovers"
            />
          </a>
        </header>

        <div
          className="w3-right"
          style={{ width: "230px", marginRight: "5px", marginTop: "-5px" }}
        >
          {this.state.accounts != 0 && (
            <button
              type="button"
              disabled
              className="connect
      w3-button w3-light-grey w3-section"
            >
              connected: {this.state.accounts}
            </button>
          )}

          {this.state.accounts == 0 && (
            <button
              className="connect
      w3-button w3-light-grey w3-section"
              onClick={this.onConnectButton}
            >
              connect wallet
            </button>
          )}
        </div>

        <div className="w3-content">
          <div className="w3-padding-32" id="about">
            <div className="w3-padding-large">
              <h1>Participate</h1> <br />
              <h3 className="w3-text-blue-grey">
                The Lovers and their Portraits
              </h3>
              <br />
              <span className="w3-text-blue-grey">
                Last mint was {this.state.formattedtime} CST
                <br />
                Next mint possible after 12pm CST (1pm during daylight savings)  on {this.state.nextday}
                <br />
                <span className="w3-tag w3-light-grey w3-small w3-left-align w3-hide-small">
                  note: daylight savings ignored
                </span>
                <br /> <br />
                trans-act &amp; mint in {this.state.timediff}<br />
                <span className="w3-tag w3-light-grey w3-small w3-left-align w3-hide-small">
                  participation and NFT are <i>free.</i> trans-actors pay only gas, which will be
                  partially reimbursed if funds are available
                </span>
              </span>
              <h4>{this.state.mintmessage}</h4>
              <form onSubmit={this.onTransact}>
                {((this.state.timeleft != 0)||(this.state.mintmessage)) && (
                  <button
                    type="button"
                    disabled
                    className="w3-button w3-light-grey w3-section"
                  >
                    trans-act & mint
                  </button>
                )}

                {((this.state.timeleft == 0)&&(!this.state.mintmessage)) && (<div>
                  <button
                    type="submit"
                    className="w3-button w3-light-grey w3-section"
                  >
                    trans-act & mint
                  </button>
                  </div>
                )}
              </form>
              
              <hr />
              <div
                className="w3-display-container w3-light-grey"
                style={{ width: "100%" }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.decodedSVG }}
                  alt="Imperfect Lovers most recent Portrat"
                />

                <span className="w3-tag w3-light-grey w3-small w3-left-align w3-hide-small">
                  Most recent <i>Portrait</i> of The Lovers. Precise gift percentages and real-time balances below. <br />
                  Lover 0 -- Last gift: {this.state.gift1}% | Current balance:  {this.state.balance1} Eth<br />
                  Lover 1 -- Last gift: {this.state.gift2}% | Current balance: {this.state.balance2} Eth<br />
                  <a href={this.state.nftURL} target="_blank" >
                  View on OpenSea
                  </a>
                </span>
              </div>
              <br />
              <p>
                At or after 12pm CST on any given day (or, more than 24 hours
                since the last trans-action), one and only one person can pay
                gas fees to enact a co-operation between Lover 0 and Lover 1,
                where they each give of themselves to the other. The
                Lovers&apos; updated balances are re-presented by their minute
                hands, while the most recent gifts are their hour hands.
                Participants in this process will receive a unique NFT: a
                Portrait of the Lovers at that precise moment.
                 <i>This trans-action and NFT are <b>free</b></i>; participants only pay gas, 
                which will be partially reimbursed when funds are available
      
                &ndash; up to .01 Eth, dependent on the Lovers&apos; balances and current block
                fees &ndash; consequently
                depleting the Lovers with every gifting.
              </p>
              <hr />
              <h3 className="w3-text-blue-grey">
                Donations and the Donor Wall
              </h3>
              <p>
                Participants might also donate to the main “Co_Operator”
                contract or either Lover (and thus to both), whose wallet
                addresses are public, thus filling their cups and/or making them
                active again.
              </p>
              <div className="w3-small">
                Currently, 15% of <i>Imperfect Lovers‘</i> donations are paid
                back to the artist.
              </div>
              <p>
                Co_Operator address: {coopaddress} <br />
                Lover 0 address: {this.state.lover0}
                <br />
                Lover 1 address: {this.state.lover1}
                <br />{" "}
              </p>
              <p>
                If you choose to donate .125 Eth or more, you also have the
                option of leaving your name and/or a message on its public
                “Donor Wall,” which lives permanently on-chain. Any donations
                will immediately be seen via the <i>Imperfect Lovers</i> data
                feed API, and become part of the Lovers&apos; image-based
                balancing with their next trans-action.
              </p>
              <form onSubmit={this.onSubmit}>
                <div>
                  <p>
                    <label>
                      donation in Eth
                      <input
                        value={this.state.value}
                        onChange={(event) =>
                          this.setState({ value: event.target.value })
                        }
                        className="w3-input w3-padding-16"
                        type="text"
                        placeholder=".125 or more"
                        required
                        name="amount"
                      />
                    </label>
                  </p>
                </div>{" "}
                <br />
                <div>
                  <label>
                    donor wall text
                    <br />
                    <i className="w3-small">
                      the administrator reserves the right to delete
                      inappropriate messages
                    </i>
                    <h4>{this.state.donormessage}</h4>
                    <input
                      maxLength={64}
                      donortext={this.state.donortext}
                      onChange={(event) =>
                        this.setState({ donortext: event.target.value })
                      }
                      className="w3-input w3-padding-16"
                      type="text"
                      placeholder="&lt; = 64 character message"
                      required
                      name="msg"
                    />
                  </label>
                  <p>
                    <button
                      className="w3-button w3-light-grey w3-section"
                      type="submit"
                    >
                      donate
                    </button>
                  </p>
                </div>
              </form>
              <h3 className="w3-text-blue-grey">Donor Wall</h3>
              {this.state.renderedDonors}
              <hr />
              <div
                className="w3-display-container w3-light-grey"
                style={{ width: "100%" }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.decodedSVG2 }}
                  alt="Imperfect Lovers most recent Portrait"
                />

                <span className="w3-tag w3-light-grey w3-small w3-left-align w3-hide-small">
                  <i>Portrait 0</i>, on The Lovers&apos; inauguration day. <a href="https://opensea.io/assets/ethereum/0x60f660e599186369049130f03bbcc7070dc52874/3" target="_blank" >
                  View on OpenSea
                  </a>
                </span>
              </div>
              <br />
              <h3 className="w3-text-blue-grey">API</h3>
              <p>
                Artists and designers are invited to produce their own
                interfaces for The Lovers. At present you can freely access all
                "Lover Data" with the solidity call getLoverData() to the
                contract at the Co_Operator address above (tested with both
                ethers.js and web3.js), which returns an object containing
                addresses, current balances, and the most recent gifts. You can use the Contract Application Binary Interface (ABI) 
                found <a href="https://imperfectlovers.art/cooperator.js">here</a>.  A
                simpler, web2 API may be made available in the future.
              </p>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
export default Participate;
